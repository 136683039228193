import {cva, VariantProps} from "class-variance-authority";

// docs:
// https://github.com/andrew-levy/swiftui-react-native/blob/master/src/utils/fonts/index.ts
// https://tailwindcss.com/docs/font-size

const typographyText = cva("span", {
  variants: {
    variant: {
      largeTitle: [ "text-[33px]", "font-normal" ], // fontSize: 34px
      title: [ "text-[27px]", "font-normal" ], // fontSize: 28px
      title2: [ "text-[21px]", "font-normal" ], // fontSize: 22px
      title3: [ "text-xl", "font-normal" ], // fontSize: 20px
      headline: [ "text-[16px]", "font-opensans-bold" ], // fontSize: 17px
      body: [ "text-[14px]", "font-normal" ], // fontSize: 17px
      callout: [ "text-[14px]", "font-normal" ], // fontSize: 16px
      subheadline: [ "text-[13px]", "font-normal" ], // fontSize: 15px
      footnote: [ "text-[11px]", "font-normal" ], // fontSize: 13px
      caption: [ "text-xs", "font-normal" ], // fontSize: 12px
      caption2: [ "text-[10px]", "font-normal" ], // fontSize: 11px

      custom: [],
    },
    color: {
      default: "text-black",
      white: "text-white",
      primary: "text-primary",
      secondary: "text-secondary",
      danger: "text-red-500",
      label: "text-neutral-600",
      meta: "text-neutral-600",
    },
    fontWeight: {
      regular: 'font-normal',
      bold: 'font-opensans-bold',
      heavy: 'font-extrabold',
      medium: 'font-opensans-semibold',
      light: 'font-light',
    }
  },
  compoundVariants: [
    {
      className: "font-opensans",
    }
  ],
  defaultVariants: {
    color: "default",
    variant: "body",
    fontWeight: "regular",
  },
});

export interface TypographyProps
  extends React.ComponentProps<any>,
    VariantProps<typeof typographyText> {}

export function Typography({
  children,
  variant,
  color,
  className,
  ...props
}: TypographyProps) {
  return (
    <div className={typographyText({ variant, color, className })} {...props}>
      {children}
    </div>
  );
}
